import { changeFormValues } from '../dados-cadastrais/actions'
import { changeMessageDelay } from '../layout/actions'
import { API_PATH } from '../../commons/constants'
import fetchApi from '../../commons/fetch-api'

export const MODAL_ADD_ENDERECO_VISIBILITY =
  'CADASTRO/MODAL_ENDERECO_VISIBILITY'
export const MODAL_CONFIRM_REMOVE_ENDERECO_VISIBILITY =
  'CADASTRO/MODAL_CONFIRM_REMOVE_ADDRESS_VISIBILITY'
export const REMOVE_ADDRESS = 'CADASTRO/REMOVE_ADDRESS_VISIBILITY'

const API_CEP = 'https://viacep.com.br/ws/:cep/json/'

export const setModalEnderecoVisibility = data => ({
  type: MODAL_ADD_ENDERECO_VISIBILITY,
  data,
})

export const setModalConfimRemoveEnderecoVisibility = data => ({
  type: MODAL_CONFIRM_REMOVE_ENDERECO_VISIBILITY,
  data,
})

export const removerEndereco = idType => ({
  type: REMOVE_ADDRESS,
  idType,
})

export const preencheEnderecoComCEP = (accountId, zipCode) => async (
  dispatch,
  state
) => {
  const tmpAddress = state.form_alter_cadastro.tmpAddress
  const zipCodeAddress = await fetchApi(
    API_PATH.conta.retrieveAddressZipCodeInfo
      .replace(':id', accountId)
      .replace(':cep', zipCode),
    { method: 'GET' }
  )
  if (!zipCodeAddress) {
    tmpAddress.zipCode = ''
    tmpAddress.address = ''
    tmpAddress.complement = ''
    tmpAddress.district = ''
    tmpAddress.state = ''
    tmpAddress.city = ''
    tmpAddress.number = ''
    tmpAddress.token = ''
    dispatch(changeFormValues({ tmpAddress }))
    return
  }
  if (tmpAddress.updating && tmpAddress.zipCode === zipCodeAddress.zipCode) {
    tmpAddress.complement =
      tmpAddress.complement && tmpAddress.complement.length > 0
        ? tmpAddress.complement
        : zipCodeAddress.complement
    tmpAddress.number =
      tmpAddress.number && tmpAddress.number.length > 0
        ? tmpAddress.number
        : zipCodeAddress.number
  } else {
    tmpAddress.complement =
      zipCodeAddress.complement && zipCodeAddress.complement.length > 0
        ? zipCodeAddress.complement
        : ''
    tmpAddress.number =
      zipCodeAddress.number && zipCodeAddress.number.length > 0
        ? zipCodeAddress.number
        : ''
  }
  tmpAddress.address =
    zipCodeAddress.address && zipCodeAddress.address.length > 0
      ? zipCodeAddress.address
      : ''
  tmpAddress.district =
    zipCodeAddress.district && zipCodeAddress.district.length > 0
      ? zipCodeAddress.district
      : ''
  tmpAddress.state =
    zipCodeAddress.state && zipCodeAddress.state.length > 0
      ? zipCodeAddress.state
      : ''
  tmpAddress.city =
    zipCodeAddress.city && zipCodeAddress.city.length > 0
      ? zipCodeAddress.city
      : ''
  tmpAddress.token = zipCodeAddress.token
  tmpAddress.hasAddress =
    zipCodeAddress.address !== '' && zipCodeAddress.address !== null
  tmpAddress.hasDistrict =
    zipCodeAddress.district !== '' && zipCodeAddress.district !== null
  dispatch(changeFormValues({ tmpAddress }))
}

export const retrieveAddresses = () => async (dispatch, state) => {
  const addresses = await fetchApi(
    API_PATH.conta.retrieveAddresses.replace(':id', state.logged_user.id),
    { method: 'GET' }
  )
  if (addresses) {
    dispatch(changeFormValues({ addresses }))
  }
}

export const createOrUpdateAddress = () => async (dispatch, state) => {
  const pathRequest = API_PATH.conta.createOrUpdateAdress
    .replace(':id', state.logged_user.id)
    .replace(':addressId', state.form_alter_cadastro.tmpAddress.idType)

  state.form_alter_cadastro.tmpAddress.zipCode = state.form_alter_cadastro.tmpAddress.zipCode.replace(
    /\D/g,
    ''
  )
  const retornoObj = await fetchApi(pathRequest, {
    method: 'PUT',
    body: JSON.stringify(state.form_alter_cadastro.tmpAddress),
  })

  if (retornoObj) {
    dispatch(setModalEnderecoVisibility(false))
    dispatch(
      changeMessageDelay({
        type: 'SUCCESS',
        text: 'Endereço salvo com sucesso.',
      })
    )
    dispatch(retrieveAddresses())
  }
}

export const removeAddress = () => async (dispatch, state) => {
  const pathRequest = API_PATH.conta.removeAddress
    .replace(':id', state.logged_user.id)
    .replace(':addressId', state.form_alter_cadastro.removeAddress.idType)
  const retornoObj = await fetchApi(pathRequest, { method: 'DELETE' })

  if (retornoObj) {
    dispatch(
      changeMessageDelay({
        type: 'SUCCESS',
        text: 'Endereço removido com sucesso. ',
      })
    )
    dispatch(removerEndereco(state.form_alter_cadastro.removeAddress.idType))
    dispatch(setModalConfimRemoveEnderecoVisibility(false))
    dispatch(retrieveAddresses())
  }
}

export const setAddressAsDefault = idType => async (dispatch, state) => {
  const pathRequest = API_PATH.conta.defaultAddress
    .replace(':id', state.logged_user.id)
    .replace(':addressId', idType)
  const retornoObj = await fetchApi(pathRequest, { method: 'PUT' })

  if (retornoObj) {
    dispatch(
      changeMessageDelay({
        type: 'SUCCESS',
        text: 'Endereço definido como padrão. ',
      })
    )
    dispatch(retrieveAddresses())
  }
}

export const setAddressForPowerOfAttorney = idType => async (
  dispatch,
  state
) => {
  const pathRequest = API_PATH.conta.powerOfAttorneyAddress
    .replace(':id', state.logged_user.id)
    .replace(':addressId', idType)
  const retornoObj = await fetchApi(pathRequest, { method: 'PUT' })

  if (retornoObj) {
    dispatch(
      changeMessageDelay({
        type: 'SUCCESS',
        text: 'Endereço para procurações definido. ',
      })
    )
    dispatch(retrieveAddresses())
  }
}
