export const API_URL = `${window.location.protocol}//${window.location.hostname}`
export const API_PATH = {
  conta: {
    start: `${API_URL}/api/login-openid`,
    home: `${API_URL}/api/`,
    logout: `${API_URL}/api/logout`,
    getConta: `${API_URL}/api/contas/:id`,
    updateName: `${API_URL}/api/contas/:id/alteracoes-nome`,
    tokenAlteracaoEmail: `${API_URL}/api/contas/:id/tokens-alteracao-email`,
    alteracoesEmail: `${API_URL}/api/contas/:id/alteracoes-email`,
    updateEmail: `${API_URL}/api/contas/:id/alteracoes-email`,
    tokenAlteracaoPhone: `${API_URL}/api/contas/:id/tokens-alteracao-telefone`,
    alteracoesPhone: `${API_URL}/api/contas/:id/alteracoes-telefone`,
    photo: `${API_URL}/api/contas/:id/foto`,
    getAvatar: `${API_URL}/api/userinfo/picture`,
    saveCadastro: `${API_URL}/api/conta/:id`,
    updatePassword: `${API_URL}/api/contas/:id/alteracoes-senha`,
    retrieveDevices: `${API_URL}/api/contas/dispositivos/conta/:id`,
    removeDevice: `${API_URL}/api/contas/dispositivos/conta/:id/devices/:deviceId`,

    retrieveAddresses: `${API_URL}/api/contas/:id/enderecos`,
    retrieveAddressZipCodeInfo: `${API_URL}/api/contas/:id/enderecos/cep/:cep`,
    createOrUpdateAdress: `${API_URL}/api/contas/:id/enderecos/:addressId`,
    removeAddress: `${API_URL}/api/contas/:id/enderecos/:addressId`,
    defaultAddress: `${API_URL}/api/contas/:id/enderecos/:addressId/endereco-padrao`,
    powerOfAttorneyAddress: `${API_URL}/api/contas/:id/enderecos/:addressId/endereco-procuracoes`,
  },
}

export const INITIAL_STATE = {
  aux_store: {},
  logged_user: {
    id: '',
    name: '',
    email: '',
    emailVerified: '',
    phoneNumber: '',
    phoneNumberVerified: '',
    amr: '',
    cnpj: '',
    photoBase64: '',
    multifator: false,
    multifatorOnline: false,
    reliabilityLevel: '',
  },
  layout: {
    loading: false,
    title: '',
    breadcrumbs: [],
    message: {},
  },
  form_alter_cadastro: {
    cpf: '',
    name: '',
    email: '',
    phoneNumber: '',
    emailVerified: false,
    token: '',
    otp: '',
    phoneNumberVerified: false,
    fileName: '-',
    errors: [],
    tmpAddress: {},
    addresses: [],
    address: {},
    removeAddress: {
      tipo: '',
    },
  },
  form_alter_senha: {
    cpf: '',
    atual: '',
    nova: '',
    repeticao: '',
    errors: [],
  },
  form_seguranca: {
    otp: '',
    token: '',
    operation: '',
    errorCode: '',
  },
  modal_visibility: {
    email_open: false,
    phone_open: false,
  },
  modal_address_visibility: {
    address_open: false,
    confirm_remove_address_open: false,
  },
  dropdown_menu: {
    avatar: false,
    servico: false,
  },
}
